function SectionTestimonials1() {
    return (
        <>
            <div className="sx-testimonial-bx-1">
                <div className="owl-carousel testimonial-2-wrap owl-btn-vertical-center">
                    {/*One block*/}
                    <div className="item">
                        <div className="testimonial-2  hover-animation-11">
                            <div className="testimonial-text">
                                <span className="testimonial-quote sx-text-primary"><i className="flaticon-quote" /></span>
                                <p>At our core, we believe that technology should empower businesses, not complicate them. With a passion for crafting tailor-made software solutions, we bridge the gap between complex problems and seamless digital experiences. Our journey is fueled by innovation, precision, and an unwavering commitment to delivering solutions that truly make a difference.</p>
                            </div>
                            <div className="testimonial-detail">
                                <div className="testimonial-user-info">
                                    <h4 className="testimonial-name">Sachintha Niyangoda</h4>
                                    <span className="testimonial-position">Founder & Visionary Leader</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*One two*/}
                    <div className="item">
                        <div className="testimonial-2  hover-animation-11">
                            <div className="testimonial-text">
                                <span className="testimonial-quote sx-text-primary"><i className="flaticon-quote" /></span>
                                <p>Artificial Intelligence is transforming the way businesses operate, and we're here to ensure that our clients stay ahead of the curve. From automation to predictive analytics, we integrate AI into software solutions that are not only intelligent but also efficient and scalable. Every project we take on is an opportunity to push boundaries and redefine what's possible with AI.</p>
                            </div>
                            <div className="testimonial-detail">
                                <div className="testimonial-user-info">
                                    <h4 className="testimonial-name">Akash Induruwa</h4>
                                    <span className="testimonial-position">AI Specialist</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*One three*/}
                    <div className="item">
                        <div className="testimonial-2  hover-animation-11">
                            <div className="testimonial-text">
                                <span className="testimonial-quote sx-text-primary"><i className="flaticon-quote" /></span>
                                <p>Building robust, scalable, and high-performing software is more than just writing code—it's about understanding the unique needs of each business and crafting solutions that align with their goals. Whether it's web applications, mobile apps, or enterprise-grade software, we engineer solutions that drive growth and efficiency for our clients.</p>
                            </div>
                            <div className="testimonial-detail">
                                <div className="testimonial-user-info">
                                    <h4 className="testimonial-name">Sahan Thilakaratne</h4>
                                    <span className="testimonial-position">Software Development Expert</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </>
    )
}

export default SectionTestimonials1;